<div class="layout-topbar">
    <div class="layout-topbar-left">
        <a href="#" class="topbar-menu-button" (click)="appMain.onMenuButtonClick($event)"
            *ngIf="appMain.isOverlay() || appMain.isMobile()">
            <i class="pi pi-bars"></i>
        </a>

        <a href="#">
            <img [src]="logo+ (app.colorScheme === 'light' ?  'light':'dark' ) + '.png'" class="app-name" />
        </a>
    </div>

    <app-menu></app-menu>

    <div class="layout-topbar-right">
        <ul class="layout-topbar-right-items">
            <li #profile class="profile-item" [ngClass]="{'active-topmenuitem':appMain.activeTopbarItem === profile}">
                <a href="#" (click)="appMain.onTopbarItemClick($event,profile)">
                    <p-avatar *ngIf="avatar" image="{{avatar}}" shape="circle"></p-avatar>
                    <p-avatar *ngIf="!avatar" label="{{initials}}"></p-avatar>
                </a>

                <ul class="fadeInDown">
                    <!-- <li role="menuitem" >
                        <a routerLink="/account/profile">
                            <i class="pi pi-fw pi-user"></i>
                            <span>Perfil</span>
                        </a>
                    </li> -->
                    <li role="menuitem">
                        <a routerLink="/account/configuration">
                            <i class="pi pi-fw pi-cog"></i>
                            <span>Configuración</span>
                        </a>
                    </li>
                    <li role="menuitem">
                        <a (click)="logout()">
                            <i class="pi pi-fw pi-sign-out"></i>
                            <span>Salir</span>
                        </a>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
</div>
