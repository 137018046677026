import { Component, OnInit } from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { AppComponent } from 'src/app/app.component';
import { AppMainComponent } from 'src/app/app.main.component';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { getDefaultProfile, getUserRoles } from 'src/app/core/functions';
import { ProfileService } from 'src/app/shared/profile.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { SharedSytleService } from 'src/app/shared/shared-style.service';
import { Permission } from 'src/app/core/enums/permissions';
import { Profile } from 'src/app/core/enums/profile';

@Component({
  selector: 'app-menu',
  templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {
  public model: any[];
  isAuthenticated: boolean;
  profiles: any
  profile: any;
  subsProfile: Subscription;
  logo: string;
  constructor(
    public app: AppComponent,
    public appMain: AppMainComponent,
    public afAuth: AngularFireAuth,
    public router: Router,
    private permissionsService: NgxPermissionsService,
    public profileService: ProfileService,
    private sharedSytleService: SharedSytleService) { }

  ngOnInit() {
    this.logo = this.sharedSytleService.logo;
    this.afAuth.idTokenResult.subscribe((user) => {
      if (user) {
        console.log("user", user);
        const permissions = getUserRoles(user.claims);
        this.permissionsService.loadPermissions(permissions);
        this.profile = getDefaultProfile(user.claims);
        console.log("profile", this.profile);
        this.subsProfile = this.profileService.getProfile()
          .subscribe(x => {
            console.log("profile", x);
            this.profile = x;
            this.checkItemMenuProfile(this.router.url);
          });
      }
    });


    this.model = [
      {
        label: 'Dashboard',
        icon: 'pi pi-fw pi-th-large',
        routerLink: ['/dashboard'],
        roles: [],
        profiles: [],
        show: true
      },
      {
        label: 'Socios',
        icon: 'pi pi-fw pi-id-card',
        routerLink: ['/members'],
        roles: [],
        profiles: [],
        show: true
      },
      {
        label: 'Planes',
        icon: 'pi pi-fw pi-list',
        routerLink: ['/plans'],
        roles: [],
        profiles: [],
        show: true
      },

      {
        label: 'Suscripciones',
        icon: 'pi pi-fw pi-calendar',
        routerLink: ['/subscriptions'],
        roles: [],
        profiles: [],
        show: true
      },

      {
        label: 'Pagos',
        icon: 'pi pi-fw pi-money-bill',
        routerLink: ['/payments'],
        roles: [],
        profiles: [],
        show: true
      },
      {
        roles: [Permission.Admin],
        profiles: [Profile.Admin],
        separator: true,
      },
      {
        label: 'Usuarios', icon: 'pi pi-fw pi-users', routerLink: ['/users'],
        roles: [Permission.Admin],
        profiles: [Profile.Admin],
        show: true
      },
      {
        label: 'Logs',
        iconFamily: 'fontawesome',
        icon: ['far', 'rectangle-list'],
        routerLink: ['/logs'],
        roles: [Permission.Admin],
        profiles: [Profile.Admin],
        show: true,
      }
    ];
  }

  checkItemMenuProfile(url: string) {
    let searchUrl = url.split("/")[1];
    let item = this.model.find(p => p.profiles && p.routerLink.includes("/" + searchUrl));
    if (!item) {
      this.router.navigate(['/dashboard']);
    }
  }
  checkProfile(itemProfiles) {
    let profileOk = itemProfiles ? itemProfiles.includes(this.profile) : true;
    if (profileOk && itemProfiles.length > 1)
      profileOk = true;

    if (itemProfiles.length == 0)
      profileOk = true;

    return profileOk;
  }

  showDivider(index: number) {
    return index == 0 || this.model[index - 1].section != this.model[index].section;
  }
}
